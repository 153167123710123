"use strict";
const uuid = require("uuid");
Object.defineProperty(exports, "__esModule", { value: true });
var web_sdk_analytics_1 = require("@scopely/web-sdk-analytics");
var web_sdk_analytics_standard= require("@scopely/standard-events");
const React = require("react");

// QA API Key
var apiKey = '88d14a7b-a983-4e6b-a9fd-e4a92bf29467';
// appName and locale
var appName = 'com.gsn.gsncasino';
var platform = 'webstore';
var locale = 'EN_US';
// Set the build type to production if the NODE_ENV is production
var buildType = process.env.NODE_ENV === 'production' ? web_sdk_analytics_1.BuildType.Production : web_sdk_analytics_1.BuildType.Debug;
var trackerSettings = new web_sdk_analytics_1.Settings(apiKey, buildType);
var tracker = new web_sdk_analytics_1.BrowserTracker(trackerSettings);
tracker.register();

class Analytics extends React.Component {
    componentDidMount() {
        if (typeof window !== "undefined" && window.addEventListener) {
            if (tracker && typeof tracker.track === "function") {
                const context = 'login from main view';
                tracker.track('sys.connect', {
                    connection: 'ScopelyId',
                    id: '1234567',
                    context: context,
                });

                const batchAppProperties = {
                    "cs.synthetic_id": uuid.v1(),
                };            
    
                tracker.updateAppProperties(batchAppProperties);
            } else {
                console.error("Tracker is not available or track is not a function.");
            }
            window.addEventListener("logAnalyticsEvent", this.handleAnalyticsEvent);
        } else {
            console.error("window is not available, unable to attach event listener.");
        }
    }

    componentWillUnmount() {
        if (typeof window !== "undefined" && window.removeEventListener) {
            window.removeEventListener("logAnalyticsEvent", this.handleAnalyticsEvent);
        }
    }

    handleAnalyticsEvent = (event) => {
        if (!event.detail) return;
        
        const data = event.detail;
        console.log("Received analytics event:", data);

        if (tracker && typeof tracker.updateAppProperties === "function") {
            const batchAppProperties = {
                "cs.loyalty_points": data.cs_loyalty_points,
                "cs.loyalty_tier": data.cs_loyalty_tier,
                "cs.level": data.cs_level,
                ...(data.cs_session_id ? { "cs.session_id": data.cs_session_id } : {}),
                "cs.platform": data.cs_platform,
            };            

            tracker.updateAppProperties(batchAppProperties);
        } else {
            console.error("Tracker is not available or track is not a function.");
        }

        if (tracker && typeof tracker.track === "function" && data.eventName) {
            tracker.track(data.eventName, data);
        } else {
            console.error("Tracker is not available or track is not a function.");
        }
    };

    render() {
        return null;
    }
}

exports.default = Analytics;
